import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic_scolaire_1 from '../assets/images/pic_scolaire_1.jpg'
import pic_scolaire_2 from '../assets/images/pic_scolaire_2.jpg'
import pic_scolaire_3 from '../assets/images/pic_scolaire_3.jpg'
import pic_scolaire_4 from '../assets/images/pic_scolaire_4.jpg'
import pic_scolaire_5 from '../assets/images/pic_scolaire_5.jpg'
import pic_scolaire_6 from '../assets/images/pic_scolaire_6.jpg'
import pic_scolaire_7 from '../assets/images/pic_scolaire_7.jpg'
import pic_scolaire_8 from '../assets/images/pic_scolaire_8.jpg'
import pic_scolaire_9 from '../assets/images/pic_scolaire_9.jpg'
import pic_scolaire_10 from '../assets/images/pic_scolaire_10.jpg'
import pic_scolaire_15 from '../assets/images/pic_scolaire_15.jpg'
import Biographie from "../components/Biographie";
import pic_mariage_6 from "../assets/images/pic_mariage_6.jpg";
import pic_mariage_1 from "../assets/images/pic_mariage_8.jpg";
import pic_mariage_3 from "../assets/images/pic_mariage_3.jpg";

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe scolaire</title>
            <meta name="description" content="Vincent DUCOS photographe scolaire Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Photos scolaires</h2>
                    </header>
                    <p>
                        Photographe scolaire spécialiste de la photographie en milieu scolaire depuis plus de 7 ans.<br />
                        La photo scolaire permet aux établissement, aux élèves et à leur famille de conserver le souvenir du temps passé à l'école.<br />
                        <br />Je vous propose des services adaptés à votre établissement: école maternelle, élémentaire, collège et lycée.
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <span className="image "><img src={pic_scolaire_6} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Prise de contact</h3>
                            </header>
                            <p>
                                Lors de notre premier rendez-vous, je vous proposerai un produit adapté et personnalisé pour chaque niveau:<br />
                                - un catalogue de cartonnages pour les photos de groupes<br />
                                - un catalogue des modèles de planches pour les portraits des élèves<br />
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <span className="image "><img src={pic_scolaire_15} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Vente en ligne</h3>
                            </header>
                            <p>
                                Afin de simplifier le processus de vente et de réduire la charge administrative des établissements scolaires.
                                <br/>La mise en place d'une plateforme de vente en ligne sécurisée permet aux institutions de suivre les commandes en temps réel.
                                <br/>De plus, elle fournit aux familles une interface à la fois rapide et intuitive.
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <span className="image "><img src={pic_scolaire_8} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Vente traditionnelle</h3>
                            </header>
                            <p>
                                Je vous fournirai les différents documents nécessaires pour simplifier la gestion et la vente des photos aux familles :
                                <br/>- une feuille de tarifs nominative et personnalisée pour chaque élève
                                <br/>- bon de commande au format A4 avec le portrait de l'enfant et/ou sa photo de classe
                                <br/><br/>Le contenu des produits souhaité à la vente sera à définir ensemble.
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <span className="image "><img src={pic_scolaire_5} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Trombinoscope</h3>
                            </header>
                            <p>
                                Livraison à la fin de la prise de vue d'une base d'images permettant l'édition d'un trombinoscope de l'ensemble des éleves de l'établissement (Charlemagne, Pronote, Molière, Sconet etc...) sur un DVD
                            </p>
                        </div>
                    </div>
                </section>
            </section>
            <section id="one">
                <div className="inner">
                    <div className="box alt">
                        <div className="grid-wrapper">
                            <div className="col-4"><span className="image fit"><img src={pic_scolaire_9} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_scolaire_10} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_scolaire_7} alt="" /></span></div>
                        </div>
                    </div>
                </div>
            </section>
            <Biographie />
        </div>

    </Layout>
)

export default Landing